//Svg
import logo from "./svgs/logo.svg";
import SmartDigitalActivition from "./svgs/SmartDigitalActivition.svg";
import BusinessSystemsSolutions from "./svgs/BusinessSystemsSolutions.svg";
import EcommerceSolutions from "./svgs/EcommerceSolutions.svg";
import EducationDigitalInteraction from "./svgs/EducationDigitalInteraction.svg";
import UI from "./svgs/UI.svg";
import ProductDevelopment from "./svgs/ProductDevelopment.svg";
import Download from "./svgs/download.svg";
import Dots from "./svgs/Dots.svg";
import BackLayer from "./svgs/BackLayer.svg";
import Line from "./svgs/Line.svg";
import DownLogo from "./svgs/DownLogo.svg";
import Facebook from "./svgs/ant-design_facebook-filled.svg";
import Instagram from "./svgs/ant-design_instagram-filled.svg";
import Tiktok from "./svgs/akar-icons_tiktok-fill.svg";
import Twitter from "./svgs/ant-design_twitter-square-filled.svg";
import Youtube from "./svgs/akar-icons_youtube-fill.svg";
import Linkedin from "./svgs/akar-icons_linkedin-box-fill.svg";
import Vector from "./svgs/Group 1 (2).svg";
import TopLogo from "./svgs/TopLogo.svg";
import Group3 from "./svgs/Group 3.svg";
import Group6 from "./svgs/Group 6.svg";
//images
import Screen from "./svgs/Screen.svg";
import Review from "./images/Review.png";
import firstItem from "./svgs/Group (1).svg";
import secondItem from "./svgs/Group (2).svg";
import thirdItem from "./svgs/Group (3).svg";
import fourthItem from "./svgs/Group (4).svg";
import fifthItem from "./svgs/Group (5).svg";
import sixthItem from "./svgs/Group (6).svg";
import seventhItem from "./svgs/Group (7).svg";
import eigtethItem from "./svgs/Group (8).svg";
import first from "./svgs/Groupfirst.svg";
import upward from "./svgs/upward.svg";
import downward from "./svgs/Group 2.svg";
import phone from "./svgs/ic_round-phone.svg";
import email from "./svgs/mdi_email.svg";
import submmit from "./svgs/Group 230177.svg";
import lightgreen from "./svgs/Group 230178.svg";
export {
  phone,
  email,
  logo,
  SmartDigitalActivition,
  BusinessSystemsSolutions,
  EcommerceSolutions,
  EducationDigitalInteraction,
  UI,
  ProductDevelopment,
  Download,
  Dots,
  BackLayer,
  Line,
  DownLogo,
  Facebook,
  Instagram,
  Tiktok,
  Twitter,
  Youtube,
  Linkedin,
  TopLogo,
  Screen,
  Review,
  Group3,
  Group6,
  firstItem,
  secondItem,
  thirdItem,
  fourthItem,
  fifthItem,
  sixthItem,
  seventhItem,
  eigtethItem,
  first,
  Vector,
  downward,
  submmit,
  upward,
  lightgreen,
};
