import {
  Form,
  Navigate,
  Route,
  Router,
  Routes,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import {
  logo,
  phone,
  email,
  SmartDigitalActivition,
  BusinessSystemsSolutions,
  EcommerceSolutions,
  EducationDigitalInteraction,
  UI,
  ProductDevelopment,
  Download,
  Line,
  DownLogo,
  Facebook,
  Group3,
  Group6,
  Instagram,
  LineTwo,
  Tiktok,
  Twitter,
  Youtube,
  Linkedin,
  Vector,
  TopLogo,
  Screen,
  firstItem,
  secondItem,
  thirdItem,
  fourthItem,
  fifthItem,
  sixthItem,
  first,
  seventhItem,
  eigtethItem,
} from "./assets";
import DownloadButton from "./DownloadButton";
import {
  MouseParallaxContainer,
  MouseParallaxChild,
} from "react-parallax-mouse";
import Submit from "./Submit";
import Home from "./Home";
import From from "./From";
import Hi from "./Hi";

function App() {
  const text =
    "Financak offers ﬁrst-class ﬁnancial solutions through a holistic ﬁnancial platform,\n where Individuals can accommodate their ﬁnancing needs through one single platform..";
  const Types = [
    {
      Logo: first,
      Text: "Solar Energy",
    },
    {
      Logo: firstItem,
      Text: "Home Improvement",
    },
    {
      Logo: secondItem,
      Text: "Club Memberships",
    },
    {
      Logo: thirdItem,
      Text: "Auto Loan",
    },
    {
      Logo: fourthItem,
      Text: "Education",
    },
    {
      Logo: fifthItem,
      Text: "Insurance Policies",
    },
    {
      Logo: sixthItem,
      Text: "Motorcycles, Scooters",
    },
    {
      Logo: seventhItem,
      Text: "Events & Weddings",
    },
    {
      Logo: eigtethItem,
      Text: "Insurance Policies",
    },
  ];
  const Social = [
    {
      Logo: Facebook,
      Type: "link",
      Link: "https://www.facebook.com/profile.php?id=100092236915243",
    },
    {
      Logo: Instagram,
      Type: "link",
      Link: "#3",
    },

    {
      Logo: Linkedin,
      Type: "link",
      Link: "https://www.linkedin.com/company/financakeg",
    },
    {
      Logo: phone,
      Type: "phone",
      Link: "tel:01094587908",
    },
    {
      Logo: email,
      Type: "email",
      Link: "mailto:Contact@financak.com",
    },
  ];

  return (
    <>
      <Routes>
        <Route path="/" element={<Home></Home>}></Route>
        <Route path="/submitted" element={<Submit></Submit>}></Route>
        <Route path="/form" element={<From></From>}></Route>
        <Route path="/Hi" element={<Hi></Hi>}></Route>
      </Routes>
    </>
  );
}

export default App;
