import { Navigate, Router, useNavigate } from "react-router-dom";
import "./App.css";
import {
  logo,
  phone,
  email,
  SmartDigitalActivition,
  BusinessSystemsSolutions,
  EcommerceSolutions,
  EducationDigitalInteraction,
  UI,
  ProductDevelopment,
  Download,
  Line,
  DownLogo,
  Facebook,
  Group3,
  Group6,
  Instagram,
  LineTwo,
  Tiktok,
  Twitter,
  Youtube,
  Linkedin,
  Vector,
  TopLogo,
  Screen,
  firstItem,
  secondItem,
  thirdItem,
  fourthItem,
  fifthItem,
  sixthItem,
  first,
  seventhItem,
  eigtethItem,
} from "./assets";
import DownloadButton from "./DownloadButton";
import {
  MouseParallaxContainer,
  MouseParallaxChild,
} from "react-parallax-mouse";

function Home() {
  const text =
    "Financak offers ﬁrst-class ﬁnancial solutions through a holistic ﬁnancial platform,\n where Individuals can accommodate their ﬁnancing needs through one single platform..";
  const Types = [
    {
      Logo: first,
      Text: "Solar Energy",
    },
    {
      Logo: firstItem,
      Text: "Home Improvement",
    },
    {
      Logo: secondItem,
      Text: "Club Memberships",
    },
    {
      Logo: thirdItem,
      Text: "Auto Loan",
    },
    {
      Logo: fourthItem,
      Text: "Education",
    },
    {
      Logo: fifthItem,
      Text: "Insurance Policies",
    },
    {
      Logo: sixthItem,
      Text: "Motorcycles, Scooters",
    },
    {
      Logo: seventhItem,
      Text: "Events & Weddings",
    },
    {
      Logo: eigtethItem,
      Text: "Insurance Policies",
    },
  ];
  const Social = [
    {
      Logo: Facebook,
      Type: "link",
      Link: "https://www.facebook.com/profile.php?id=100092236915243",
    },
    {
      Logo: Instagram,
      Type: "link",
      Link: "#3",
    },

    {
      Logo: Linkedin,
      Type: "link",
      Link: "https://www.linkedin.com/company/financakeg",
    },
    {
      Logo: phone,
      Type: "phone",
      Link: "tel:01094587908",
    },
    {
      Logo: email,
      Type: "email",
      Link: "mailto:Contact@financak.com",
    },
  ];
  const navigate = useNavigate();
  return (
    <html>
      <div className="App" style={{ position: "relative" }}>
        <img
          src={Vector}
          style={{ position: "absolute", top: "0", left: "0" }}
          className="Vector"
        ></img>

        <header>
          <img src={logo} className="App-logo" />
        </header>
        <div className="subServices">
          <div>
            <img src={Group3} className="arrow-img"></img>
          </div>
          <div className="services">
            <span>Our Services</span>
          </div>{" "}
          <div>
            <img src={Group6}></img>
          </div>
        </div>
        <div className="MainContainer">
          <div className="TextContainer">
            <p className="Services" style={{ marginBottom: "6rem" }}>
              YOUR FINANCING SOLUTION
            </p>

            <span class="A-Brand-Mark-is-a-Symbol-Element-Art-Design">
              {text}
            </span>

            <div className="Column">
              {Types.map((item) => {
                return (
                  <div className="Types">
                    <img src={item.Logo} className="Type-Logo" />
                    <div style={{ marginTop: 10 }}>
                      <span class="Type-Text">{item.Text}</span>
                      <div class="Line-1"></div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="Column2">
              {/* download buttom */}
              <DownloadButton />

              <button
                className="SecButton"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/form")}
              >
                {" "}
                <p className="DownloadText">Submit a Request</p>
                <img src={Download} className="DownSvg" />
              </button>
            </div>
          </div>
          <div></div>

          <div className="ScreenContainer">
            <div factorX={0.1} factorY={0.1}>
              <img src={Screen} className="Screen" />
            </div>
          </div>
        </div>
        {/* <div className="scroll-parent">
          <div className="scroll-element primary">
            <img src={Line} />
          </div>
          <div className="scroll-element secondary">
            <img src={Line} />
          </div>
        </div> */}
        <div>
          <div>
            <p className="FollowUs">Follow Us.</p>
            <div className="Social">
              {Social.map((item) => {
                return (
                  <a href={item.Link}>
                    <img
                      src={item.Logo}
                      className="SocialIcon"
                      style={{ marginRight: ".51vw", marginTop: "-1vw" }}
                    />
                  </a>
                );
              })}
            </div>
          </div>
        </div>
        <div className="Bottom-container" style={{ bottom: "0", left: "80%" }}>
          <img src={DownLogo} className="DownLogo" />
        </div>
      </div>
      {/* <img src={TopLogo} className='TopLogo' /> */}
    </html>
  );
}

export default Home;
