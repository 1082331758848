import React, { useState } from "react";
import { logo, downward, upward, submmit, lightgreen } from "./assets";
import { useNavigate } from "react-router-dom";

function Submit() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    service: "",
    amount: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://financak.o-projects.org/api/v1/store",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        // Form submission successful
        navigate("/");
      } else {
        // Handle API error
        console.error("Error submitting the form:", response);
      }
    } catch (error) {
      // Handle network error
      console.error("Error submitting the form:", error);
    }
    // Do something with the form data, e.g., send it to an API or perform any required actions
    console.log("Form data:", formData);
  };

  return (
    <div style={{ position: "relative" }}>
      <link
        rel="stylesheet"
        href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.0/css/bootstrap.min.css"
        integrity="sha384-9gVQ4dYFwwWSjIDZnLEWnxCjeSWFphJiwGPXr1jddIhOegiu1FwO5qRGvFXOdJZ4"
        crossorigin="anonymous"
      ></link>
      <div>
        <img src={upward} style={{ width: "30%", position: "absolute" }}></img>
      </div>
      <div style={{ height: "100vh", alignItems: "center", display: "flex" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            width: "100%",
          }}
        >
          <div>
            <img src={submmit} style={{ width: "26%" }}></img>

            <div>
              <p className="Services" style={{ marginBottom: "6rem" }}>
                Your Request is submitted
              </p>
            </div>
            <div onClick={() => navigate("/")}>
              <span
                className="Download"
                style={{ width: "100%", padding: "1rem" }}
              >
                <span
                  style={{
                    color: "white",
                    fontFamily: "fatura",
                    fontSize: "1rem",
                    cursor: "pointer",
                  }}
                >
                  Done
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <img
          src={lightgreen}
          style={{
            width: "26%",
            position: "absolute",
            bottom: "0",
            right: "0",
          }}
        ></img>
      </div>
    </div>
  );
}

export default Submit;
