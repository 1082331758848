import React, { useState } from "react";
import { logo, downward, upward } from "./assets";
import { useNavigate } from "react-router-dom";

function From() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    service: "",
    amount: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://findashboard.o-projects.org/api/v1/store",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        // Form submission successful
        navigate("/submitted");
      } else {
        // Handle API error
        console.error("Error submitting the form:", response);
      }
    } catch (error) {
      // Handle network error
      console.error("Error submitting the form:", error);
    }
    // Do something with the form data, e.g., send it to an API or perform any required actions
    console.log("Form data:", formData);
  };

  return (
    <div>
      <link
        rel="stylesheet"
        href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.0/css/bootstrap.min.css"
        integrity="sha384-9gVQ4dYFwwWSjIDZnLEWnxCjeSWFphJiwGPXr1jddIhOegiu1FwO5qRGvFXOdJZ4"
        crossorigin="anonymous"
      ></link>
      <div className="form-page">
        <div className="col-6" style={{ position: "relative", width: "100%" }}>
          <div className="form-left">
            <div>
              <img src={upward} style={{ width: "104%" }}></img>
            </div>
            <img
              src={logo}
              style={{ position: "absolute", top: "50%", left: "25%" }}
            ></img>
            <div>
              <img
                src={downward}
                style={{ width: "67%", position: "absolute", bottom: "0" }}
              ></img>
            </div>
          </div>
        </div>
        <div className="col-md-6" style={{ width: "100%", padding: "5vh" }}>
          <div>
            <span
              className="Services"
              style={{ marginBottom: "4rem", color: "#2D7D2F" }}
            >
              Submit a Request
            </span>
            <span
              class=" A-Brand-Mark-is-a-Symbol-Element-Art-Design "
              style={{ display: "block" }}
            >
              Fill Your Data To Confirm Your Request Please.
            </span>
            <form onSubmit={handleSubmit}>
              <div className="row" style={{ marginTop: "2rem" }}>
                <div className="col-12" style={{ height: "fit-content" }}>
                  <label className="form-label">Name</label>
                  <input
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="form-input"
                  />
                </div>
                <div className="col-12" style={{ height: "fit-content" }}>
                  <label className="form-label">Mobile Number</label>
                  <input
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    className="form-input"
                  />
                </div>
                <div className="col-8" style={{ height: "fit-content" }}>
                  <label className="form-label">Services</label>
                  <select
                    className="form-input"
                    name="service"
                    value={formData.service}
                    onChange={handleChange}
                  >
                    <option value="">Choose your service</option>

                    <option value="Solar Energy">Solar Energy</option>
                    <option value="Home Improvement">Home Improvement</option>
                    <option value="Club Memberships">Club Memberships</option>
                    <option value="Auto Loan">Auto Loan</option>
                    <option value="Education">Education</option>
                    <option value="Insurance Policies">
                      Insurance Policies
                    </option>
                    <option value="Motorcycles, Scooters">
                      Motorcycles, Scooters
                    </option>
                    <option value="Events & Weddings">Events & Weddings</option>
                    <option value="Insurance Policies">
                      Insurance Policies
                    </option>
                  </select>
                </div>
                <div className="col-4" style={{ height: "fit-content" }}>
                  <label className="form-label">Amount</label>
                  <input
                    name="amount"
                    value={formData.amount}
                    onChange={handleChange}
                    className="form-input"
                  />
                </div>
                <div className="col-12">
                  <button
                    type="submit"
                    className="Download"
                    style={{ width: "100%", padding: "1rem" }}
                  >
                    <span
                      style={{
                        color: "white",
                        fontFamily: "fatura",
                        fontSize: "1.2rem",
                      }}
                    >
                      Submit
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default From;
