import React from 'react';
import { saveAs } from 'file-saver';
import PDFFILE from "./assets/Presentation_Financak.pdf";
const DownloadButton = () => {
  return (
    <a
        href={PDFFILE}
        download="Presentation Financak"
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: "none" }}
      >
        <button className="Download" style={{ cursor: "pointer" }}>
            <p className="DownloadText" style={{ color: "white" }}>
                Download Profile
            </p>
        </button>
      </a>
    
    
  );
};

export default DownloadButton;
